<template>
  <div class="w-100 h-100 overflow-auto">
    <d-medical-devices-models />
  </div>
</template>
<script>

export default {
  name: 'MedicalDeviceModels',
};
</script>
